@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@300;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #101010;
    color: white;
}

.tooltip {
    @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
    @apply visible z-50;
}